import React from "react";
import Layout from "../components/Layout";

function Resources({location}) {
  return (
    <div>
      <Layout location={location}>RESOURCES</Layout>
    </div>
  );
}

export default Resources;
